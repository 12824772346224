import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/Icons/LogoIcon';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';
import theme from 'theme/theme';

import { useCorporateContext } from '../CorporateContext';
import { selectMonthPeriod } from '../helpers';

import HealthImprovementProgramSection from './sections/HealthImprovementProgramSection';
import HeroSection from './sections/HeroSection';
import PricingSection from './sections/PricingSection';
import ShidoPowerSection from './sections/ShidoPowerSection';
import SuccessStoriesSection from './sections/SuccessStoriesSection';
import YourPartnerSection from './sections/YourPartnerSection';
import StickyHeader from './StickyHeader';
import WebsiteFooter from './WebsiteFooter';

const OfferIntroContent = ({ isLoading }: { isLoading: boolean }) => {
  const { offerTerm, images, accountName, isShowDepartment } =
    useCorporateContext();

  const [isExpired, setIsExpired] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const monthPeriod = selectMonthPeriod(offerTerm);

  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  const partnerRef = useRef<HTMLDivElement>(null);
  const powerRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  const pricesRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <StickyHeader accountName={accountName} />
      <HeroSection
        accountName={accountName}
        logo={images.logo}
        backgroundImg={images.background}
        monthPeriod={monthPeriod}
        isLoadingData={isLoading}
        isExpired={isExpired}
        setIsExpired={() => setIsExpired(true)}
        isSuspended={isSuspended}
        setIsSuspended={() => setIsSuspended(true)}
        isShowDepartment={isShowDepartment}
        notFound={!isLoading && !offerTerm}
      />
      <HealthImprovementProgramSection
        onScroll={() =>
          partnerRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <YourPartnerSection
        ref={partnerRef}
        isMobile={isMobile}
        onScroll={() =>
          powerRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <ShidoPowerSection
        ref={powerRef}
        onScroll={() =>
          successRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <SuccessStoriesSection
        ref={successRef}
        withFooter={isExpired || isSuspended}
        onScroll={() =>
          pricesRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      />
      {isExpired || isSuspended ? (
        <>
          <PricingSection ref={pricesRef} />
          <WebsiteFooter withGetAppSection />
        </>
      ) : (
        <LogoFooter>
          <LogoIcon width={70} height={44} fill={theme.colors.topicBlue} />
          <TradeMark>®</TradeMark>
        </LogoFooter>
      )}
    </Container>
  );
};

const TradeMark = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  top: -1px;
  right: -15px;
  position: absolute;
`;

const LogoFooter = styled.footer`
  position: relative;
  margin: 0 auto;
  width: 70px;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  padding-bottom: 130px;
  padding-top: 6px;
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  overflow-x: hidden;
`;

export default OfferIntroContent;
