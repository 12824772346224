/* eslint-disable camelcase */

import { CURRENCY_SYMBOLS, CurrencyCode, ProductDetails } from '../types';

export const getPriceWithCurrency = (
  price: string,
  currency: CurrencyCode,
  withSymbol = true
) => {
  const currencySymbol = CURRENCY_SYMBOLS[currency];

  switch (currency) {
    case 'EUR':
      return withSymbol ? `${price}${currencySymbol}` : price;
    case 'GBP':
    case 'USD':
    default:
      return withSymbol ? `${currencySymbol}${price}` : price;
  }
};

export const populatePriceTokens = (
  product: ProductDetails,
  currency: CurrencyCode,
  withSymbol = true
): ProductDetails => {
  const { unitAmount } = product.currencies[currency.toLowerCase()];

  const replacePrices = (text: string) =>
    text
      .replace(
        /\{currencyPrice\}/g,
        getPriceWithCurrency(`${unitAmount / 100}`, currency)
      )
      .replace(
        /\{currencyPricePerMonth\}/g,
        getPriceWithCurrency(
          (unitAmount / 12 / 100).toFixed(2),
          currency,
          withSymbol
        )
      );

  return {
    ...product,
    description: replacePrices(product.description),
    metadata: {
      trial_option_body: replacePrices(product.metadata.trial_option_body),
      trial_option_disclaimer: replacePrices(
        product.metadata.trial_option_disclaimer
      ),
      trial_option_free_period: replacePrices(
        product.metadata.trial_option_free_period
      ),
      trial_option_monthly_price: replacePrices(
        product.metadata.trial_option_monthly_price
      ),
      trial_option_monthly_price_desc: replacePrices(
        product.metadata.trial_option_monthly_price_desc
      ),
      trial_option_plan: replacePrices(product.metadata.trial_option_plan),
      trial_period_days: product.metadata.trial_period_days,
    },
  };
};
