import React, { FC, SVGProps } from 'react';

export const GoogleIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.0565 10.3267L13.8592 10.3262C13.4089 10.3262 13.0439 10.6911 13.0439 11.1414V14.399C13.0439 14.8492 13.4089 15.2142 13.8592 15.2142H19.6017C18.9729 16.8461 17.7992 18.2127 16.3019 19.0811L18.7504 23.3198C22.6783 21.0482 25.0004 17.0624 25.0004 12.6006C25.0004 11.9653 24.9536 11.5111 24.86 10.9998C24.7888 10.6112 24.4515 10.3267 24.0565 10.3267Z"
      fill="#167EE6"
    />
    <path
      d="M12.5004 20.1084C9.69019 20.1084 7.23687 18.5729 5.91924 16.3008L1.68066 18.7438C3.83765 22.4822 7.87837 24.9997 12.5004 24.9997C14.7679 24.9997 16.9074 24.3892 18.7504 23.3252V23.3194L16.3019 19.0807C15.1818 19.7303 13.8857 20.1084 12.5004 20.1084Z"
      fill="#12B347"
    />
    <path
      d="M18.75 23.3256V23.3198L16.3014 19.0811C15.1814 19.7306 13.8854 20.1087 12.5 20.1087V25.0001C14.7674 25.0001 16.907 24.3896 18.75 23.3256Z"
      fill="#0F993E"
    />
    <path
      d="M4.89131 12.5C4.89131 11.1148 5.26934 9.8189 5.9188 8.69893L1.68022 6.25586C0.610449 8.09312 0 10.2268 0 12.5C0 14.7733 0.610449 16.907 1.68022 18.7442L5.9188 16.3012C5.26934 15.1812 4.89131 13.8853 4.89131 12.5Z"
      fill="#FFD500"
    />
    <path
      d="M12.5004 4.89131C14.333 4.89131 16.0163 5.54248 17.3311 6.62564C17.6555 6.89282 18.1269 6.87354 18.424 6.57642L20.7321 4.26831C21.0692 3.9312 21.0452 3.37939 20.6851 3.06699C18.4822 1.15591 15.616 0 12.5004 0C7.87837 0 3.83765 2.51743 1.68066 6.25581L5.91924 8.69888C7.23687 6.42676 9.69019 4.89131 12.5004 4.89131Z"
      fill="#FF4B26"
    />
    <path
      d="M17.3307 6.62563C17.655 6.89282 18.1265 6.87354 18.4236 6.57642L20.7317 4.26831C21.0688 3.9312 21.0447 3.37939 20.6847 3.06699C18.4818 1.15586 15.6156 0 12.5 0V4.89131C14.3325 4.89131 16.0159 5.54248 17.3307 6.62563Z"
      fill="#D93F21"
    />
  </svg>
);
