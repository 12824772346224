/* eslint-disable camelcase */
import { AxiosError } from 'axios';
import React, { FC } from 'react';
import { useMutation } from 'react-query';

import { useGoogleLogin } from '@react-oauth/google';
import { googleAuthRequest } from 'modules/corporateOffer/services';
import { AuthResponse, AuthType } from 'modules/corporateOffer/types';

import { AuthButton } from './AuthButton';

type Props = {
  onSuccess: (data: AuthResponse) => void;
  onError?: (error: string) => void;
};

export const GoogleAuthButton: FC<Props> = ({ onSuccess, onError }) => {
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (codeResponse) => {
      mutate(codeResponse.code);
    },
  });

  const { isLoading, mutate } = useMutation<
    AuthResponse,
    AxiosError<string>,
    string
  >('googleAuth', googleAuthRequest, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      onError?.(error.response?.data || 'Unexpected error');
    },
  });

  return (
    <AuthButton
      type={AuthType.Google}
      disabled={isLoading}
      onClick={() => login()}
    />
  );
};
