import { AxiosError } from 'axios';
import config from 'config';
import React, { FC } from 'react';
import {
  AppleAuthResponse,
  appleAuthHelpers,
  useScript,
} from 'react-apple-signin-auth';
import { useMutation } from 'react-query';

import {
  AppleAuthPayload,
  appleAuthRequest,
} from 'modules/corporateOffer/services';
import { AuthResponse, AuthType } from 'modules/corporateOffer/types';

import { AuthButton } from './AuthButton';

type Props = {
  onSuccess: (data: AuthResponse) => void;
  onError?: (error: string) => void;
};

export const AppleAuthButton: FC<Props> = ({ onSuccess, onError }) => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const { isLoading, mutate } = useMutation<
    AuthResponse,
    AxiosError<string>,
    AppleAuthPayload
  >('appleAuth', appleAuthRequest, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      const errorMessage =
        typeof error.response?.data === 'object'
          ? 'Unexpected error'
          : error.response?.data;

      onError?.(errorMessage || 'Unexpected error');
    },
  });

  const handleAuthClick = () => {
    if (!config.appleClientId) return;

    const redirectUri = `${window.location.origin}/`;

    appleAuthHelpers.signIn({
      authOptions: {
        clientId: config.appleClientId,
        redirectURI: redirectUri,
        scope: 'email name',
        usePopup: true,
      },
      onSuccess: (response: AppleAuthResponse) => {
        mutate({ ...response, redirectUri });
      },
    });
  };

  return (
    <AuthButton
      type={AuthType.Apple}
      disabled={isLoading}
      onClick={handleAuthClick}
    />
  );
};
