import React, { FC, SVGProps } from 'react';

export const EmailIcon: FC<SVGProps<SVGSVGElement>> = ({
  color = '#62B0CB',
  ...props
}) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1143 10.556C11.349 10.79 11.6669 10.9214 11.9983 10.9214C12.3297 10.9214 12.6476 10.79 12.8823 10.556L22.5683 0.870001C22.6341 0.80448 22.6803 0.721945 22.7018 0.63164C22.7233 0.541335 22.7192 0.446826 22.6901 0.358696C22.6609 0.270567 22.6078 0.192299 22.5366 0.132656C22.4655 0.0730135 22.3792 0.0343519 22.2873 0.0210014C22.1923 0.00699992 22.0963 -1.87866e-05 22.0003 1.47098e-06H2.0003C1.90358 -0.000116842 1.80698 0.00690213 1.7113 0.0210014C1.61943 0.0343519 1.53309 0.0730135 1.46196 0.132656C1.39082 0.192299 1.3377 0.270567 1.30853 0.358696C1.27936 0.446826 1.2753 0.541335 1.29681 0.63164C1.31831 0.721945 1.36453 0.80448 1.4303 0.870001L11.1143 10.556Z"
      fill={color}
    />
    <path
      d="M23.888 1.83088C23.8546 1.81669 23.8177 1.81282 23.782 1.81977C23.7464 1.82672 23.7136 1.84417 23.688 1.86988L17.488 8.06988C17.4412 8.11692 17.415 8.18055 17.415 8.24688C17.415 8.31322 17.4412 8.37685 17.488 8.42388L22.531 13.4669C22.6027 13.536 22.6598 13.6188 22.6992 13.7103C22.7385 13.8017 22.7593 13.9001 22.7602 13.9997C22.7611 14.0993 22.7422 14.1981 22.7045 14.2903C22.6668 14.3825 22.6112 14.4662 22.5408 14.5367C22.4704 14.6071 22.3867 14.6629 22.2945 14.7006C22.2024 14.7384 22.1036 14.7574 22.0041 14.7566C21.9045 14.7557 21.806 14.7351 21.7145 14.6958C21.623 14.6566 21.5402 14.5995 21.471 14.5279L16.428 9.48488C16.4048 9.4616 16.3772 9.44313 16.3468 9.43053C16.3164 9.41792 16.2839 9.41144 16.251 9.41144C16.2181 9.41144 16.1856 9.41792 16.1552 9.43053C16.1248 9.44313 16.0972 9.4616 16.074 9.48488L13.945 11.6139C13.4293 12.1293 12.7301 12.4188 12.001 12.4188C11.2719 12.4188 10.5727 12.1293 10.057 11.6139L7.926 9.48688C7.87897 9.44011 7.81533 9.41385 7.749 9.41385C7.68267 9.41385 7.61903 9.44011 7.572 9.48688L2.529 14.5299C2.38748 14.6664 2.198 14.7419 2.00135 14.7401C1.8047 14.7383 1.61663 14.6594 1.47764 14.5203C1.33865 14.3811 1.25986 14.193 1.25824 13.9963C1.25663 13.7997 1.33231 13.6103 1.469 13.4689L6.512 8.42588C6.55878 8.37885 6.58503 8.31522 6.58503 8.24888C6.58503 8.18255 6.55878 8.11892 6.512 8.07188L0.312 1.87188C0.286508 1.84594 0.253752 1.82832 0.21805 1.82136C0.182347 1.8144 0.145373 1.81842 0.112 1.83288C0.0791449 1.84657 0.0510241 1.86959 0.0311174 1.8991C0.0112108 1.9286 0.000392021 1.96329 0 1.99888L0 13.9989C0 14.5293 0.210714 15.038 0.585786 15.4131C0.960859 15.7882 1.46957 15.9989 2 15.9989H22C22.5304 15.9989 23.0391 15.7882 23.4142 15.4131C23.7893 15.038 24 14.5293 24 13.9989V1.99888C24.0001 1.96292 23.9895 1.92774 23.9696 1.89782C23.9497 1.86789 23.9212 1.84459 23.888 1.83088Z"
      fill={color}
    />
  </svg>
);
