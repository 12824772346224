import React from 'react';
import styled, { css } from 'styled-components';

import CircleArrowIcon from 'components/Icons/CircleArrowIcon';
import getDeviceInfo, { DeviceType } from 'helpers/getDeviceInfo';

type Props = {
  onScroll: () => void;
};

const HealthImprovementProgramSection = ({ onScroll }: Props) => {
  const isMobile = getDeviceInfo().platform === DeviceType.Mobile;

  return (
    <Container>
      <Content>
        <Wrapper $isReversed>
          <FirstImageWrapper>
            <img
              src="/images/healthProgram/app-1.png"
              width={654}
              height={628}
              alt="Wellness program"
            />
          </FirstImageWrapper>
          <div>
            <Title style={{ marginTop: isMobile ? 5 : 158 }}>
              {isMobile
                ? 'With expert-driven guidance powered by smart AI technology, we design a unique wellness program tailored to your lifestyle & health goals.'
                : `With expert-driven guidance powered by smart\nAI technology, we design a unique wellness program\ntailored to your lifestyle & health goals.`}
            </Title>
            <Subtitle>
              {isMobile
                ? 'Our personalised guidance uses your data to form actionable health strategies, transforming daily habits into health wisdom, & aspirations into victories.'
                : `Our personalised guidance uses your data to form\nactionable health strategies, transforming daily habits\ninto health wisdom, & aspirations into victories.`}
            </Subtitle>
          </div>
        </Wrapper>
        <Divider />
        <Wrapper $withLeftMargin $isReversed={false}>
          <div>
            <Title
              $withRightAlign
              style={{
                marginTop: isMobile ? 30 : 114,
              }}
            >
              {isMobile
                ? 'Shido evolves as you do, adjusting your program as you progress, celebrating your victories, & offering support every step of your wellness journey.'
                : `Shido evolves as you do, adjusting your program as you\nprogress, celebrating your victories, & offering support\nevery step of your wellness journey.`}
            </Title>
            <Subtitle $withRightAlign>
              {isMobile
                ? 'With Shido, your health journey is never a solo mission. Your 24/7 health companion, provides immediate advice on workouts, diet, stress management & more.'
                : `With Shido, your health journey is never a solo mission.\nYour 24/7 health companion, provides immediate advice\non workouts, diet, stress management & more.`}
            </Subtitle>
          </div>
          <SecondImageWrapper>
            <img
              src="/images/healthProgram/app-2.png"
              width={654}
              height={628}
              alt="Wellness journey"
            />
          </SecondImageWrapper>
        </Wrapper>
        <ArrowCircleContainer
          onClick={onScroll}
          data-testid="health-program-arrow"
        >
          <CircleArrowIcon uniqueID="health-program-arrow" />
        </ArrowCircleContainer>
      </Content>
    </Container>
  );
};

const SecondImageWrapper = styled.div`
  width: 654px;
  margin-top: -42px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 0;
    width: 375px;
    height: 319px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const FirstImageWrapper = styled.div`
  width: 654px;
  margin-left: 17px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    width: 375px;
    height: 319px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ArrowCircleTemplate = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
`;

const ArrowCircleContainer = styled(ArrowCircleTemplate)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -46px auto 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 57px auto 0;
  }
`;

const Divider = styled.div`
  width: 4px;
  height: 125px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 2px;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 43%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 80px;
    height: 4px;
    position: static;
    margin: 57px auto 0;
  }
`;

const Title = styled.h5<{ $withRightAlign?: boolean }>`
  font-size: 20px;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.topicBlue};
  font-weight: 400;
  margin-bottom: 59px;
  white-space: pre-line;
  ${({ $withRightAlign }) =>
    $withRightAlign &&
    css`
      text-align: right;

      ${({ theme }) => theme.breakpoints.down('md')} {
        text-align: center;
      }
    `}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    white-space: unset;
    max-width: 318px;
    text-align: center;
  }
`;

const Wrapper = styled.div<{ $isReversed: boolean; $withLeftMargin?: boolean }>`
  display: flex;
  gap: 29px;
  ${({ $withLeftMargin }) =>
    $withLeftMargin &&
    css`
      margin-left: 180px;

      ${({ theme }) => theme.breakpoints.down('md')} {
        margin-left: 0;
      }
    `}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    align-items: center;
    flex-direction: ${({ $isReversed }) =>
      $isReversed ? 'column-reverse' : 'column'};
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1400px;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    align-items: center;
  }
`;

const Container = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  padding-bottom: 78px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    align-items: center;
    padding-bottom: 38px;
  }
`;

const Subtitle = styled.p<{ $withRightAlign?: boolean }>`
  font-size: 20px;
  line-height: 35px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.topicBlue};
  opacity: 0.8;
  white-space: pre-line;
  ${({ $withRightAlign }) =>
    $withRightAlign &&
    css`
      text-align: right;

      ${({ theme }) => theme.breakpoints.down('md')} {
        text-align: center;
      }
    `}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 22px auto 0;
    font-size: 16px;
    line-height: 26px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    white-space: unset;
    max-width: 334px;
    text-align: center;
  }
`;

export default HealthImprovementProgramSection;
