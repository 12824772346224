export enum AuthType {
  Apple = 'apple',
  Google = 'google',
  Email = 'email',
}

export type AuthResponse = {
  token: string;
  refreshToken: string;
};
