export enum SignUpPlan {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
  FREE = 'free',
}

export type ProductDetails = {
  id: string;
  description: string;
  currency: string;
  productId: string;
  productName: string;
  unitAmount: number;
  signUpPlan: SignUpPlan;
  recurringInterval: 'month' | 'year';
  isRecommended: boolean;
  metadata: {
    trial_option_body: string;
    trial_option_disclaimer: string;
    trial_option_free_period: string;
    trial_option_monthly_price: string;
    trial_option_monthly_price_desc: string;
    trial_option_plan: string;
    trial_period_days: number;
  };
  currencies: Record<string, { unitAmount: number }>;
};
