import React, { FC, MouseEvent } from 'react';
import { styled } from 'styled-components';

import { AuthType } from 'modules/corporateOffer/types';

import { AppleIcon, EmailIcon, GoogleIcon } from './icons';

type Props = {
  type: AuthType;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const AuthButton: FC<Props> = ({ type, disabled, onClick }) => {
  const { Icon, label } = getButtonConfig(type);

  return (
    <Button onClick={onClick} disabled={disabled} aria-disabled={disabled}>
      <IconWrapper>{Icon && <Icon />}</IconWrapper>
      {label}
      {/* Empty wrapper to preserve flex layout */}
      <IconWrapper />
    </Button>
  );
};

const getButtonConfig = (type: AuthType) => {
  switch (type) {
    case AuthType.Apple:
      return { label: 'apple', Icon: AppleIcon };
    case AuthType.Google:
      return { label: 'google', Icon: GoogleIcon };
    case AuthType.Email:
      return { label: 'email', Icon: EmailIcon };

    default:
      return { label: '', Icon: null };
  }
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
`;

const Button = styled.button`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.funtainBlue};
  color: ${({ theme }) => theme.colors.funtainBlue};
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding: 10px 16px;
  width: 250px;
  filter: drop-shadow(0 10px 20px rgb(0 0 0 / 20%));

  &:hover {
    filter: drop-shadow(0 10px 20px rgb(0 0 0 / 30%));
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: left;
    gap: 20px;
    padding: 10px;
    width: 158px;
  }
`;
