import React from 'react';
import styled from 'styled-components';

import CircleArrowIcon from 'components/Icons/CircleArrowIcon';
import MobileCarousel from 'components/MobileCarousel';

import YourPartnerCarousel from '../YourPartnerCarousel';
import {
  AppForEverythingCarouselDataDesktop,
  AppForEverythingCarouselDataMobile,
} from '../YourPartnerCarousel/carouselData';

import { ArrowCircleTemplate } from './HealthImprovementProgramSection';
import { DashedLine } from './ShidoPowerSection';

type Props = {
  isMobile: boolean;
  onScroll: () => void;
};

const YourPartnerSection = (
  { isMobile, onScroll }: Props,
  ref: React.Ref<HTMLDivElement>
) => (
  <Container ref={ref}>
    <DashedLine />
    <Title>Shido: Your partner for every health goal</Title>
    <Subtitle>Weight loss, stress management, improved sleep & more.</Subtitle>
    <Description>
      {`Whatever your health aspiration, Shido is by your side. Looking to shed a few pounds? Desiring to better manage stress?\nNeed to improve your sleep? Shido crafts custom plans & tools to cater to all this & more, continually adapting to your needs.`}
    </Description>
    {isMobile ? (
      <MobileCarousel>
        {AppForEverythingCarouselDataMobile.map(({ id, text, imageSrc }) => (
          <Card key={id}>
            <ImageWrapper>
              <NextImageWrapper>
                <img src={imageSrc} alt={text} />
              </NextImageWrapper>
            </ImageWrapper>
            <p>{text}</p>
          </Card>
        ))}
      </MobileCarousel>
    ) : (
      <YourPartnerCarousel
        isDesktop={!isMobile}
        slides={AppForEverythingCarouselDataDesktop}
      />
    )}
    <ArrowCircleContainer
      onClick={onScroll}
      data-testid="app-for-everything-arrow"
    >
      <CircleArrowIcon uniqueID="app-for-everything-arrow" />
    </ArrowCircleContainer>
    <DashedLine />
  </Container>
);

const ArrowCircleContainer = styled(ArrowCircleTemplate)`
  margin: 80px auto 82px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 18px;
    margin-bottom: 38px;
  }
`;

const Title = styled.h3`
  font-size: 40px;
  line-height: 60px;
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  font-weight: 400;
  margin-top: 67px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 35px;
    margin-top: 30px;
  }
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
  white-space: pre-line;
  margin-top: 34px;
  color: ${({ theme }) => theme.colors.topicBlue};
  opacity: 0.8;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    white-space: normal;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 330px;
    margin: 22px auto 0;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.topicBlue};
  text-align: center;
  margin-top: 18px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 318px;
    margin: 21px auto 0;
  }
`;

const NextImageWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -21px;
  width: 185px;
  height: 185px;
  transform: translate(-50%, 0);

  img {
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.section`
  position: relative;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  z-index: 20;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  top: 40px;
  margin: 0 auto;

  &::after {
    content: '';
    position: absolute;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border: 2px dashed ${({ theme }) => theme.colors.secondary};
    z-index: 25;
  }
`;

const Card = styled.div`
  width: 200px;
  height: 240px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};

  p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 88px;
    text-align: center;
  }
`;

export default React.forwardRef(YourPartnerSection);
