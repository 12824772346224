import React from 'react';
import styled from 'styled-components';

import CircleArrowIcon from 'components/Icons/CircleArrowIcon';
import DecreaseIcon from 'components/Icons/DecreaseIcon';
import IncreaseIcon from 'components/Icons/IncreaseIcon';

import { ArrowCircleTemplate } from './HealthImprovementProgramSection';

type Props = {
  onScroll: () => void;
};

const ShidoPowerSection = (
  { onScroll }: Props,
  ref: React.Ref<HTMLDivElement>
) => (
  <Container ref={ref}>
    <Title>Discover the power of Shido</Title>
    <Subtitle>Personalised. Expert-driven. Always there for you.</Subtitle>
    <Description>
      {`At Shido, we blend expert knowledge & smart AI to turn your data into personalised insights. The more you interact with Shido,\nthe more fine-tuned our recommendations become, ensuring a health companion that’s always ready to guide you.`}
    </Description>
    <ImagesWrapper>
      <div style={{ marginTop: 14, marginLeft: -11 }}>
        <img
          src="/images/shidoPower/chat.png"
          alt="Chat"
          width={329}
          height={164}
        />
      </div>
      <ShidoPowerImage>
        <img
          src="/images/shidoPower/shido-power.png"
          alt="Discover the power of Shido"
          width={374}
          height={547}
        />
      </ShidoPowerImage>
      <ProgressInfo>
        <img
          src="/images/progress-chart.svg"
          width={155}
          height={155}
          alt="progress-chart"
        />
        <ProgressList>
          <li>
            <ListItem>
              <Circle $color="#8CA2BB" />
              <h6>Energy</h6>
            </ListItem>
            <ListItem>
              <IncreaseIcon />
              <ListValue>+30%</ListValue>
            </ListItem>
          </li>
          <li>
            <ListItem>
              <Circle $color="#A154AB" />
              <h6>Stress</h6>
            </ListItem>
            <ListItem>
              <DecreaseIcon style={{ marginRight: 4 }} />
              <ListValue>-10%</ListValue>
            </ListItem>
          </li>
          <li>
            <ListItem>
              <Circle $color="#E4869F" />
              <h6>Life balance</h6>
            </ListItem>
            <ListItem>
              <IncreaseIcon />
              <ListValue>+20%</ListValue>
            </ListItem>
          </li>
        </ProgressList>
      </ProgressInfo>
    </ImagesWrapper>
    <ArrowCircleContainer onClick={onScroll} data-testid="shido-power-arrow">
      <CircleArrowIcon uniqueID="shido-power-arrow" />
    </ArrowCircleContainer>
    <DashedLine />
  </Container>
);

export const DashedLine = styled.div<{ $isDesktop?: boolean }>`
  width: 1400px;
  display: block;
  margin: 0 auto;
  height: 1px;
  opacity: ${({ $isDesktop }) => ($isDesktop ? '1' : '0.2')};
  border-top: 1px dashed ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 900px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100vw;
  }
`;

const ArrowCircleContainer = styled(ArrowCircleTemplate)`
  margin: 37px auto 82px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 31px;
    margin-bottom: 39px;
  }
`;

const ShidoPowerImage = styled.div`
  margin-top: -2px;
  margin-left: 16px;

  img {
    filter: drop-shadow(0 20px 40px rgb(0 0 0 / 20%));
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: -10px;
    margin-left: 0;
    width: 335px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 75px;
  width: 1040px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-top: 17px;
    width: 375px;
  }
`;

const ListValue = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Circle = styled.div<{ $color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

const ProgressList = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    padding-top: 7px;
    width: 166px;

    &:not(:first-child) {
      border-top: 1px dashed ${({ theme }) => theme.colors.darkYellow};
    }
  }
`;

const ProgressInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 31px;
    gap: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 2px;
  }
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
  white-space: pre-line;
  opacity: 0.8;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    max-width: 600px;
    white-space: normal;
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 325px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin-bottom: 22px;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 220px;
    margin: 0 auto 22px;
  }
`;

const Title = styled.h4`
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-align: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.topicBlue};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 25px;
  }
`;

const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 66px 0 0;
  background-color: ${({ theme }) => theme.colors.lightYellow};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 28px;
  }
`;

export default React.forwardRef(ShidoPowerSection);
