import React from 'react';
import styled from 'styled-components';

type ButtonSize = 'sm' | 'md' | 'lg';
type BorderSize = 'xs' | 'sm' | 'md' | 'lg';
type FontSize = 'md' | 'lg';

const fontSizes = {
  md: 14,
  lg: 20,
};

const buttonSizes = {
  sm: {
    w: 158,
    h: 50,
  },
  md: {
    w: 250,
    h: 50,
  },
  lg: {
    w: 225,
    h: 60,
  },
};

const borderSizes = {
  xs: {
    w: 162,
    h: 54,
  },
  sm: {
    w: 166,
    h: 58,
  },
  md: {
    w: 254,
    h: 54,
  },
  lg: {
    w: 233,
    h: 68,
  },
};

export type SubmitButtonProps = {
  buttonSize: ButtonSize;
  borderSize: BorderSize;
  text: string;
  fontSize: FontSize;
  isDisabled?: boolean;
  clickHandler?: () => void;
};

const SubmitButton = ({
  isDisabled,
  buttonSize,
  borderSize,
  text,
  fontSize,
  clickHandler,
}: SubmitButtonProps) => (
  <Container
    $width={borderSizes[borderSize].w}
    $height={borderSizes[borderSize].h}
  >
    <Button
      disabled={isDisabled}
      $width={buttonSizes[buttonSize].w}
      $height={buttonSizes[buttonSize].h}
      $fontSize={fontSizes[fontSize]}
      onClick={clickHandler}
    >
      {text}
    </Button>
  </Container>
);

const Container = styled.div<{ $width: number; $height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${({ $width }) => $width}px;
  width: 100%;
  height: ${({ $height }) => $height}px;
  background-color: rgb(255 255 255 / 20%);
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  border-radius: 60px;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0 5px 20px rgb(0 0 0 / 40%);
  }
`;

const Button = styled.button<{
  $width: number;
  $height: number;
  $fontSize: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${({ $width }) => $width}px;
  width: 100%;
  height: ${({ $height }) => $height}px;
  border-radius: 60px;
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-weight: 700;
  background: padding-box ${({ theme }) => theme.colors.funtainBlue};
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border: none;

  &:disabled {
    background: padding-box ${({ theme }) => theme.colors.gray};
    cursor: not-allowed;
  }
`;

export default SubmitButton;
