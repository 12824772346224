import { capitalizeString } from 'helpers';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import SmallWarningIcon from 'components/Icons/SmallWarningIcon';
import {
  AppleAuthButton,
  AuthButton,
  GoogleAuthButton,
} from 'modules/corporateOffer/components/authButtons';
import {
  ChatActionsColContainer,
  ErrorText,
} from 'modules/corporateOffer/components/styledElements/StyledFormElements';
import { StageContainer } from 'modules/corporateOffer/components/styledElements/StyledStages';
import { chatServiceApi } from 'modules/corporateOffer/services';
import { AuthType } from 'modules/corporateOffer/types';
import theme from 'theme/theme';

import { useCorporateContext } from '../../../CorporateContext';
import ChatMessage from '../../ChatMessage';

type Props = {
  isMobile?: boolean;
  shown: boolean;
  isEditing: boolean;
  toggleEditingStatus: () => void;
  goToNextStage: (showNext: boolean) => void;
};

const SignInMethod = ({
  isMobile,
  shown,
  isEditing,
  toggleEditingStatus,
  goToNextStage,
}: Props) => {
  const { userName, codeValue, onChatSuccess } = useCorporateContext();

  const [isQuestionShown, setIsQuestionsShown] = useState(false);
  const [isActionContainerShown, setIsActionContainerShown] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<AuthType | null>(null);
  const [authError, setAuthError] = useState<string>('');
  const [isAccountExists, setIsAccountExists] = useState(false);

  // We don't need token value to trigger any renders
  const tokenRef = useRef('');

  const handleSelectMethod = (method: AuthType) => {
    setSelectedMethod(method);

    if (isEditing) {
      if (method !== AuthType.Email) {
        goToNextStage(false);
      }

      toggleEditingStatus();
    }

    setIsAccountExists(false);
    setIsActionContainerShown(false);
  };

  const handleNextStep = async () => {
    if (!selectedMethod) return;

    // For email, just trigger next chat step
    goToNextStage(selectedMethod === AuthType.Email);

    // For social logins, apply offer code and redirect to the Success stage
    // as we don't need passcode and any other info from the user
    if (selectedMethod !== AuthType.Email) {
      try {
        await chatServiceApi.applyOfferCodeToSocialAccount(
          tokenRef.current,
          codeValue
        );
        setIsAccountExists(false);
        onChatSuccess();
      } catch (err) {
        setIsAccountExists(true);
      }
    }

    setAuthError('');
  };

  return (
    <StageContainer $shown={shown}>
      <ChatMessage
        message={`Thanks ${capitalizeString(userName)}!`}
        isMobile={isMobile}
        goToNextStep={() => {
          setIsQuestionsShown(true);
        }}
      />
      {isQuestionShown && (
        <ChatMessage
          message="How would you like to sign in?"
          isMobile={isMobile}
          goToNextStep={() => setIsActionContainerShown(true)}
        />
      )}
      {!!selectedMethod && !isEditing && (
        <ChatMessage
          isOutgoing
          message={capitalizeString(selectedMethod)}
          isMobile={isMobile}
          goToNextStep={handleNextStep}
          toggleEditingStatus={toggleEditingStatus}
          doNotShowEdit={selectedMethod !== AuthType.Email}
        />
      )}
      {isAccountExists && (
        <ChatMessage
          message="Looks like your account already exists or the offer code is applied, please sign in or create a new account."
          isMobile={isMobile}
          goToNextStep={toggleEditingStatus}
        />
      )}
      {(isActionContainerShown || isEditing) && (
        <ActionsContainer $isMobile={isMobile}>
          <AppleAuthButton
            onSuccess={(data) => {
              tokenRef.current = data.token;
              handleSelectMethod(AuthType.Apple);
            }}
            onError={setAuthError}
          />
          <GoogleAuthButton
            onSuccess={(data) => {
              tokenRef.current = data.token;
              handleSelectMethod(AuthType.Google);
            }}
            onError={setAuthError}
          />
          <AuthButton
            type={AuthType.Email}
            onClick={() => handleSelectMethod(AuthType.Email)}
          />
          <Error $error={!!authError}>
            <SmallWarningIcon color={theme.colors.red} />
            {authError}
          </Error>
        </ActionsContainer>
      )}
    </StageContainer>
  );
};

const Error = styled(ErrorText)`
  position: static;
`;

const ActionsContainer = styled(ChatActionsColContainer)`
  padding: 10px 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-bottom: 16px;
  }
`;

export default SignInMethod;
