import React, { FC, SVGProps } from 'react';

export const AppleIcon: FC<SVGProps<SVGSVGElement>> = ({
  color = '#333333',
  ...props
}) => (
  <svg
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.3632 0C14.0304 0.092187 12.4726 0.945307 11.5648 2.05624C10.7366 3.06404 10.0554 4.56091 10.321 6.01559C11.7773 6.0609 13.2819 5.18747 14.1538 4.05779C14.9694 3.00623 15.5866 1.51874 15.3632 0Z"
      fill={color}
    />
    <path
      d="M20.6305 8.38749C19.3509 6.78281 17.5524 5.85156 15.854 5.85156C13.6118 5.85156 12.6634 6.92499 11.1056 6.92499C9.49936 6.92499 8.27905 5.85469 6.34 5.85469C4.43533 5.85469 2.40721 7.01874 1.12128 9.00936C-0.686519 11.8125 -0.377146 17.0827 2.55252 21.5718C3.60096 23.178 5.00095 24.9843 6.83219 24.9999C8.46186 25.0155 8.92124 23.9546 11.129 23.9436C13.3368 23.9311 13.7556 25.014 15.3821 24.9968C17.2149 24.9827 18.6915 22.9812 19.7399 21.3749C20.4915 20.2234 20.7712 19.6437 21.354 18.3437C17.1149 16.7296 16.4353 10.7015 20.6305 8.38749Z"
      fill={color}
    />
  </svg>
);
