import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ErrorMessage } from '@hookform/error-message';
import DefaultButton from 'components/DefaultButton';
import { LogoIcon } from 'components/Icons/LogoIcon';
import SmallWarningIcon from 'components/Icons/SmallWarningIcon';
import patterns from 'helpers/patterns';
import { ErrorText } from 'modules/corporateOffer/components/styledElements/StyledFormElements';
import { AppRoutes } from 'router/models';
import theme from 'theme/theme';

import { FailedAffiliateEmail, sendAffiliateEmail } from '../services';
import { AffiliateStorageKeys } from '../types';

type EmailSectionProps = {
  image: string | null;
  color: string;
  publicCode: string | undefined;
  isMobile?: boolean;
};

const EMAIL_INPUT_ID = 'EMAIL_INPUT_ID';

const OFFER_CODE_ERROR = 'affiliateCode/affiliate-offer-public-code-invalid';
const EMAIL_ERROR = 'affiliateCode/affiliate-code-email-invalid';

const EmailSection = (
  { image, color, publicCode, isMobile }: EmailSectionProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const navigate = useNavigate();

  const {
    register,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
  });

  const [activeInput, setActiveInput] = useState(false);
  const email = watch()[EMAIL_INPUT_ID];

  const { mutate, isLoading } = useMutation(sendAffiliateEmail, {
    onSuccess: () => {
      if (image) localStorage.setItem(AffiliateStorageKeys.Image, image);
      localStorage.setItem(AffiliateStorageKeys.Email, email);
      localStorage.setItem(AffiliateStorageKeys.Color, color);

      navigate(`${AppRoutes.AffiliateBase}/${AppRoutes.AffiliateInstruction}`);
    },
    onError: (error) => {
      const errorMessage = (error as AxiosError<FailedAffiliateEmail>).response
        ?.data?.payload?.Errors;

      if (errorMessage === OFFER_CODE_ERROR) {
        setError(EMAIL_INPUT_ID, {
          message: 'Offer public code is invalid',
        });
      } else if (errorMessage === EMAIL_ERROR) {
        setError(EMAIL_INPUT_ID, {
          message: 'Invalid email address',
        });
      } else {
        setError(EMAIL_INPUT_ID, {
          message: 'Something went wrong',
        });
      }
    },
  });

  const sendEmailRequest = useCallback(
    (data: FieldValues) => {
      if (publicCode) {
        mutate({
          publicCode,
          email: data.EMAIL_INPUT_ID,
        });
      }
    },
    [mutate, publicCode]
  );

  return (
    <Container ref={ref}>
      <TitleName>Start your free 1 month trial!</TitleName>
      <Subtitle>Enter your email to activate your unique offer code</Subtitle>
      <form onSubmit={handleSubmit(sendEmailRequest)}>
        <InputContainer>
          <Label
            data-testid="email-label"
            active={activeInput}
            htmlFor={EMAIL_INPUT_ID}
          >
            Enter email
          </Label>
          <StyledInput
            id={EMAIL_INPUT_ID}
            onFocus={() => setActiveInput(true)}
            {...register(EMAIL_INPUT_ID, {
              required: true,
              pattern: patterns.emailPattern,
            })}
          />
          <ErrorMessage
            name={EMAIL_INPUT_ID}
            errors={errors}
            render={({ message }) => (
              <ErrorTextAffiliate $error={!!errors[EMAIL_INPUT_ID]?.message}>
                <SmallWarningIcon color={theme.colors.red} />
                {message}
              </ErrorTextAffiliate>
            )}
          />
        </InputContainer>
        <WrapperButton>
          <DefaultButton
            isLoading={isLoading}
            text="send offer code"
            textColor={theme.colors.white}
            isActive={isValid && !isLoading}
            withShadow
            buttonColor={
              isValid && !isLoading
                ? theme.colors.funtainBlue
                : theme.colors.gray
            }
            isUppercase
            width={isMobile ? 158 : 300}
            height={isMobile ? 50 : 60}
            isSubmit
          />
        </WrapperButton>
      </form>
      <ShidoIconWrapper>
        <LogoIcon width={48} height={29} />
        <ShidoTitle>
          <ShidoMark>®</ShidoMark>
        </ShidoTitle>
      </ShidoIconWrapper>
    </Container>
  );
};

const ErrorTextAffiliate = styled(ErrorText)`
  left: 2px;
`;

const ShidoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 20px;
  position: relative;
  left: 12px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const ShidoMark = styled.div`
  position: absolute;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  top: 0;
  right: 4px;
`;

const ShidoTitle = styled.div`
  position: relative;
  top: -4px;
`;

const WrapperButton = styled.div`
  max-width: 305px;
  margin: auto;
  margin-bottom: 81px;
  display: flex;
  justify-content: center;

  @media (width <= 480px) {
    margin-bottom: 41px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  outline: none;
  border: none;
  padding-top: 25px;
  padding-left: 1px;
  box-sizing: border-box;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 1000px #fff inset;
  }
`;

const Label = styled.label<{ active: boolean }>`
  font-size: ${({ active }) => (active ? '12px' : '16px')};
  color: ${({ theme }) => theme.colors.funtainBlue};
  position: absolute;
  transform: translateY(-50%);
  transition:
    all 0.5s,
    color 0s;
  top: ${({ active }) => (active ? '20px' : '50%')};
`;

const InputContainer = styled.div`
  position: relative;
  margin: 73px auto 40px;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 6px;
  box-sizing: border-box;
  height: 60px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.funtainBlue};
  padding-bottom: 5px;
  background: white;

  @media (width <= 480px) {
    margin: 40px auto;
    max-width: 335px;
  }
`;

const TitleName = styled.div`
  text-align: center;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};

  @media (width <= 480px) {
    font-size: 20px;
  }
`;

const Subtitle = styled(TitleName)`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 300;

  @media (width <= 480px) {
    margin-top: 34px;
    font-size: 14px;
  }
`;

const Container = styled.div`
  max-width: 1400px;
  padding-bottom: 211px;
  padding-top: 55px;
  border-bottom: 1px dashed rgb(216 218 224 / 25500%);
  position: relative;
  z-index: 3;
  margin: 36px auto 0;
  box-sizing: border-box;

  @media (width <= 700px) {
    padding: 1px 5px 211px;
  }
`;

export default React.forwardRef(EmailSection);
