import config from 'config';
import React, { forwardRef } from 'react';
import styled, { css, useTheme } from 'styled-components';

import SubmitButton from 'components/SubmitButton';
import { populatePriceTokens } from 'modules/corporateOffer/helpers';
import {
  useGetCurrency,
  useGetProductPlanDetails,
} from 'modules/corporateOffer/hooks';
import { SignUpPlan } from 'modules/corporateOffer/types';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';

interface Props {}

const PricingSection = (_: Props, ref: React.Ref<HTMLDivElement>) => {
  const isMobile = useMediaQuery(useTheme()?.breakpoints.down('md'));

  const { clientCurrency } = useGetCurrency();
  const { data: rawData } = useGetProductPlanDetails();

  const buttonText = `TRY FOR FREE${isMobile ? '!' : ''}`;
  const data = rawData?.map((product) =>
    populatePriceTokens(product, clientCurrency, false)
  );

  const handleSignUpClick = () => {
    window.open(
      `${config.shidoWebsiteVersion}/sign-up?plan=${SignUpPlan.MONTHLY}`,
      '_blank'
    );
  };

  if (!data) return null;

  return (
    <Container ref={ref}>
      <Title>Sign up today for a 1 month free trial</Title>
      <Subtitle>No commitment. Cancel anytime.</Subtitle>

      <SubmitButton
        isDisabled={false}
        buttonSize={isMobile ? 'sm' : 'lg'}
        fontSize={isMobile ? 'md' : 'lg'}
        borderSize={isMobile ? 'sm' : 'lg'}
        text={buttonText}
        clickHandler={handleSignUpClick}
      />
    </Container>
  );
};

const Subtitle = styled.p<{ $hideOnMobile?: boolean }>`
  margin-top: 14px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.topicBlue};
  margin-bottom: 37px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 26px;
    margin-top: 24px;
    ${({ $hideOnMobile }) =>
      $hideOnMobile &&
      css`
        display: none;
      `}
  }
`;

const Title = styled.h3`
  font-size: 40px;
  line-height: 60px;
  color: ${({ theme }) => theme.colors.topicBlue};
  font-weight: 400;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 35px;
    max-width: 220px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  padding-bottom: 85px;
`;

export default forwardRef(PricingSection);
